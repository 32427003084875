import * as z from 'zod';
import { type User } from 'next-auth';
import { ZodError } from 'zod';
import { httpClient } from '../../http';
import {
  InvalidAuthorizationCodeError,
  InvalidCredentialsError,
  MissingDataInAuthResponseError,
  UnhandledServerError,
} from '../types/auth-error';

type Credentials = Partial<Record<'email' | 'password', unknown>>;

type Provider = 'credentials' | 'google';

const LoginResponseSchema = z.object({
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string(),
  refreshExpiresIn: z.number(),
});

async function getAuthorizationTokens(
  provider: Provider,
  credentials: Credentials | Partial<Record<'accessToken', unknown>>,
): Promise<z.infer<typeof LoginResponseSchema>> {
  const mapProviderToApiPath: Record<Provider, string> = {
    credentials: 'login',
    google: 'login-with-google',
  };

  const httpClientOptions = {
    _withAuth: false,
    _withRetry: true,
    headers: { 'Content-Type': 'application/json' },
  };

  return await httpClient.post<z.infer<typeof LoginResponseSchema>>(
    `/v1/iam/${mapProviderToApiPath[provider]}`,
    credentials,
    httpClientOptions,
  );
}

function mapAuthorizationResponse(tokens: z.infer<typeof LoginResponseSchema>): User | null {
  return {
    accessToken: tokens.accessToken,
    accessTokenExpiresIn: tokens.expiresIn,
    refreshToken: tokens.refreshToken,
    refreshTokenExpiresIn: tokens.refreshExpiresIn,
  };
}

/**
 * This function builds the user object which will be forwarded as an argument to
 * the `signIn`, `jwt` and `session` callbacks (in that order)
 * The user id is not present here: it will be extracted from the deciphered `accessToken` by next-auth between
 * the callback `authorize` and the callback `signIn`
 */
export async function authorizeCredentials(credentials: Credentials): Promise<User | null> {
  try {
    const tokens = await getAuthorizationTokens('credentials', credentials);

    LoginResponseSchema.parse(tokens);

    return mapAuthorizationResponse(tokens);
  } catch (error: any) {
    if (error instanceof ZodError) {
      throw new MissingDataInAuthResponseError();
    }
    if (error.status === 401) {
      throw new InvalidCredentialsError();
    }
    throw new UnhandledServerError();
  }
}

/**
 * This function builds the user object which will be forwarded as an argument to
 * the `signIn`, `jwt` and `session` callbacks (in that order)
 * The user id is not present here: it will be extracted from the deciphered `accessToken` by next-auth between
 * the callback `authorize` and the callback `signIn`
 */
export async function authorizeGoogleAccessToken({
  accessToken,
}: Partial<Record<'accessToken', unknown>>): Promise<User | null> {
  try {
    const tokens = await getAuthorizationTokens('google', { accessToken });

    LoginResponseSchema.parse(tokens);

    return mapAuthorizationResponse(tokens);
  } catch (error: any) {
    if (error instanceof ZodError) {
      throw new MissingDataInAuthResponseError();
    }
    if (error.status === 401) {
      throw new InvalidAuthorizationCodeError();
    }
    throw new UnhandledServerError();
  }
}
