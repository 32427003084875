import { httpClient } from '../../http/server/http-client';

export async function logout(refreshToken: string): Promise<void> {
  await httpClient.post(
    '/v1/iam/logout',
    { refreshToken },
    {
      _withRetry: true,
      headers: { 'Content-Type': 'application/json' },
    },
  );
}
