'use client';
import React, { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import { LoginMethod } from '../types/login-method';
import { AuthAction } from '../types/auth-action';
import { uriToAuth } from '../utils';

export type AuthenticationGuardProps = {
  authAppUrl: string;
  locale: string;
  method: LoginMethod;
  children: React.ReactElement;
};

export function AuthenticationGuard({
  authAppUrl,
  locale,
  method,
  children,
}: AuthenticationGuardProps): React.JSX.Element | null {
  const { data: session, status } = useSession();
  const shouldRedirect = status === 'unauthenticated' || (status === 'authenticated' && !session!.isValid);

  useEffect(() => {
    if (shouldRedirect) {
      const currentUri = window.location.href;
      window.location.href = uriToAuth({
        action: AuthAction.Login,
        authAppUrl,
        locale,
        method,
        redirectUri: currentUri,
      });
    }
  }, [authAppUrl, locale, method, shouldRedirect]);

  return children;
}
