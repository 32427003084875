import { NextURL } from 'next/dist/server/web/next-url';

export function hostnameFromUrl(url: string): string {
  /**
   * @param url an absolute url
   * @returns the host from the url
   *
   * Example:
   *   hostnameFromUrl('http://localhost:3000/foo/bar')
   *   should return 'localhost'
   */
  const parsedUrl = new URL(url);
  return parsedUrl.hostname;
}

export function isInDomain(url: string, domain: string): boolean {
  /**
   *
   * @param url - an url
   * @param domain - a domain
   * @returns true if the the url is valid, absolute and in the same domain
   *
   */

  try {
    return hostnameFromUrl(url).endsWith(domain);
  } catch {
    // The url is invalid or not absolute
    return false;
  }
}

export function withSearchParams(url: string, searchParams: URLSearchParams): string {
  /**
   * Returns the url with query params appended to it
   *
   * @param url - an absolute or relative url (it is assumed that it does not already contains query params!)
   * @param searchParams - a URLSearchParams object
   * @returns the same url with the query params of the current url appended
   *
   */
  const searchParamsString = searchParams.toString();
  if (searchParamsString.length === 0) {
    return url;
  }
  return `${url}?${searchParams}`;
}

/**
 * Replace the full host (protocol://host:port) of a NextURL
 * @param url - NextURL
 * @param newHost - the new host
 * @returns a clone of the url with the host replaced (protocol + host + port)
 */
export function replaceHost(url: NextURL, newHost: string): NextURL {
  const { protocol, host, port } = new URL(newHost);
  const newUrl = url.clone();
  newUrl.protocol = protocol;
  newUrl.host = host;
  newUrl.port = port;
  return newUrl;
}
