export class HttpError extends Error {
  public readonly status: number;
  public readonly message: string;
  public readonly errorCode?: string;

  constructor(status: number, message: string, errorCode?: string) {
    super(message);
    this.status = status;
    this.message = message;
    this.errorCode = errorCode;
  }
}

export function isHttpError(error: Error): error is HttpError {
  return (error as HttpError)?.status !== undefined;
}
