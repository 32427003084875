// eslint-disable-next-line max-classes-per-file
import { CredentialsSignin } from 'next-auth';

export enum AuthErrorCode {
  UNABLE_TO_PERFORM_REQUEST = 'UnableToPerformRequest',
  AUTH_REFRESH_TOKENS_ERROR = 'RefreshTokensError',
  AUTH_REFRESH_TOKEN_EXPIRED = 'RefreshTokenExpired',
  UNHANDLED_SERVER_ERROR = 'UnhandledServerError',
  INVALID_CREDENTIALS = 'InvalidCredentials',
  INVALID_AUTHORIZATION_CODE = 'InvalidAuthorizationCode',
  MISSING_DATA_IN_RESPONSE = 'MissingDataInResponse',
  WRONG_DATA_IN_RESPONSE = 'WrongDataInResponse',
  NOT_FETCHED_YET = 'NotFetchedYet',
}

export class InvalidCredentialsError extends CredentialsSignin {
  constructor() {
    super();
    this.code = AuthErrorCode.INVALID_CREDENTIALS;
    this.message = AuthErrorCode.INVALID_CREDENTIALS;
  }
}

export class InvalidAuthorizationCodeError extends CredentialsSignin {
  constructor() {
    super();
    this.code = AuthErrorCode.INVALID_AUTHORIZATION_CODE;
    this.message = AuthErrorCode.INVALID_AUTHORIZATION_CODE;
  }
}

export class MissingDataInAuthResponseError extends CredentialsSignin {
  constructor() {
    super();
    this.code = AuthErrorCode.MISSING_DATA_IN_RESPONSE;
    this.message = AuthErrorCode.MISSING_DATA_IN_RESPONSE;
  }
}

export class UnhandledServerError extends CredentialsSignin {
  constructor() {
    super();
    this.code = AuthErrorCode.UNHANDLED_SERVER_ERROR;
    this.message = AuthErrorCode.UNHANDLED_SERVER_ERROR;
  }
}
