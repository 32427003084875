import * as z from 'zod';
import { httpClient } from '../../http';
import { AuthErrorCode } from '../types/auth-error';

const RefreshTokensResponseSchema = z.object({
  accessToken: z.string(),
  expiresIn: z.number(),
  refreshToken: z.string(),
  refreshExpiresIn: z.number(),
});

export type RefreshTokensResponseError = { error: AuthErrorCode };
export type RefreshTokensResponse = z.infer<typeof RefreshTokensResponseSchema> | RefreshTokensResponseError;

export function isRefreshTokensError(response: RefreshTokensResponse): response is RefreshTokensResponseError {
  return !!(response as RefreshTokensResponseError).error;
}

export async function refreshTokens(refreshToken: string): Promise<RefreshTokensResponse | { error: AuthErrorCode }> {
  /**
   *
   * This function is called when the access token is expired
   * If the refresh token is still valid, then a new pair of tokens is generated
   * Otherwise, we set an AuthErrorCode
   *
   * @param refreshToken - a refresh token
   * @returns a refreshed token if its refreshToken is valid and the refresh operation is successful,
   *          the same token with an error otherwise
   *
   */
  try {
    const updatedTokens = await httpClient.post<z.infer<typeof RefreshTokensResponseSchema>>(
      '/v1/iam/refresh',
      { refreshToken },
      { _withAuth: false, _withRetry: true, headers: { 'Content-Type': 'application/json' } },
    );
    try {
      RefreshTokensResponseSchema.parse(updatedTokens);
    } catch {
      return { error: AuthErrorCode.MISSING_DATA_IN_RESPONSE };
    }
    return updatedTokens;
  } catch (error) {
    return {
      error:
        (error as any).status === 401 ? AuthErrorCode.AUTH_REFRESH_TOKEN_EXPIRED : AuthErrorCode.UNHANDLED_SERVER_ERROR,
    };
  }
}
