const KEYS_TO_OBFUSCATE = [
  // Sensitive data
  /password/i,
  /token/i,
  /secret/i,

  // Personal data
  /birth/i,
  /email/i,
  /username/i,
  /firstName/i,
  /lastName/i,
];

const MAX_OBJECT_DEPTH = 3;

export const obfuscate = <T = unknown>(input: T, depth: number = 1): T => {
  if (typeof input !== 'object' || !input) {
    return input;
  }

  if (Array.isArray(input)) {
    return input.map((value) => obfuscate(value)) as T;
  }

  return Object.entries(input).reduce(
    (acc, [key, value]) => {
      if (KEYS_TO_OBFUSCATE.some((regexp) => regexp.test(key))) {
        return { ...acc, [key]: '***' };
      }

      if (depth > MAX_OBJECT_DEPTH) {
        return { ...acc, [key]: '[...]' };
      }

      return { ...acc, [key]: obfuscate(value, depth + 1) };
    },
    {} as typeof input,
  );
};
