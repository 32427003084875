'use client';
import useSWR, { Key } from 'swr';
import { failableAction } from '../server/failable-action';
import { FailableServerAction, hasFailed } from '../server/failable-server-action';
import { ServerActionError } from '../types/server-action-error';

function redirectOrThrow(serverActionError: ServerActionError): void {
  if (serverActionError.statusCode === 401) {
    // The backend responds with an error 401 because the session is expired
    // We force a full reload of the page so that the middleware redirects us to login page
    window.location.reload();
  }
  throw serverActionError;
}

export function useFailableServerAction<T>(
  key: Key,
  failableServerAction: () => Promise<FailableServerAction<T>>,
): ReturnType<typeof useSWR<T, ServerActionError>> {
  const result = useSWR<T, ServerActionError>(key, async () => {
    const failableResult = await failableServerAction();
    if (hasFailed(failableResult)) redirectOrThrow(failableResult);
    return failableResult as T;
  });
  return result;
}

export function useServerAction<T>(
  caller: (...args: any[]) => any,
  key: Key,
  serverAction: () => Promise<T>,
): ReturnType<typeof useFailableServerAction<T>> {
  return useFailableServerAction(key, async () => failableAction(caller, serverAction));
}
