import { ServerActionError } from '../types/server-action-error';

export function hasFailed(result: FailableServerAction): result is ServerActionError {
  const isError = (result as ServerActionError)?.error !== undefined;
  return isError;
}

export function hasInternalServerError(result: FailableServerAction): result is ServerActionError {
  return hasFailed(result) && result.statusCode === 500;
}

export type FailableServerAction<T = any> = T | ServerActionError;
